export default defineI18nConfig(() => ({
  fallbackLocale: "nl",
  legacy: false,
  locale: "nl",
  availableLocales: ["nl", "en"],
  datetimeFormats: {
    en: {
      short: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      },
      long: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      },
      time: {
        hour: "numeric",
        minute: "numeric",
      },
      shortVerbose: {
        year: "numeric",
        month: "short",
        day: "numeric",
      },
    },
    nl: {
      short: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      },
      long: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      },
      time: {
        hour: "numeric",
        minute: "numeric",
      },
      shortVerbose: {
        year: "numeric",
        month: "short",
        day: "numeric",
      },
    },
  },
  numberFormats: {
    en: {
      currency: {
        style: "currency",
        currency: "EUR",
        currencyDisplay: "symbol",
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      },
      "2-digits": {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      },
      percentage: {
        style: "percent",
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      },
      percentageNoDecimals: {
        style: "percent",
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      },
    },
    nl: {
      currency: {
        style: "currency",
        currency: "EUR",
        currencyDisplay: "symbol",
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      },
      "2-digits": {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      },
      percentage: {
        style: "percent",
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      },
      percentageNoDecimals: {
        style: "percent",
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      },
    },
  },
}))
